import { LoadingCard } from '@/components/DashboardCard';
import { FileOrFolderAccordion } from '@/components/UsersAccordionList/EditPermissionsModalSteps/fileOrFolderAccordion';
import {
  hasSubItems,
  setFilesIsChecked,
} from '@/components/UsersAccordionList/EditPermissionsModalSteps/utils';
import { User } from '@types';
import { GenericWizardSteps } from '@/components/GenericWizard/GenericWizard';
import { FileItemExtended } from './interfaces';

interface FilesStepProps extends GenericWizardSteps {
  user: User;
  folderList: FileItemExtended;
  updateFiles: (folderList: FileItemExtended) => void;
  isLoading: boolean;
}

export default function FilesStep({
  user,
  folderList,
  updateFiles,
  isLoading,
}: FilesStepProps) {
  const selectFile = (fileId: string) => {
    const result = setFilesIsChecked(folderList, fileId);
    updateFiles(result);
  };

  if (isLoading) {
    return <LoadingCard />;
  }

  const displayFiles = (files: FileItemExtended[] | undefined) => {
    if (!files) {
      return null;
    }
    return files.map((file) => (
      <FileOrFolderAccordion
        open={false}
        fatherIsOpen
        level={0}
        showCheckbox
        file={file}
        setFilesIsChecked={selectFile}
        showAccordionIcon={hasSubItems(file)}
        key={`accordion-${file.id}`}
      />
    ));
  };

  return (
    <>
      <div className='flex flex-col'>
        <div className='text-sm font-bold capitalize'>
          Which documents would you like to share with {user?.first_name}{' '}
          {user?.last_name}?
        </div>
      </div>
      {folderList?.files && folderList?.files?.length > 0 ? (
        displayFiles(folderList?.files)
      ) : (
        <>
          <br />
          <span>No Files to assign</span>
        </>
      )}
    </>
  );
}
