/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '@/components/PageContainer';
import { DashboardWrapper } from '@/components/DashboardWrapper';
import { EmptyState } from '@/components/EmptyState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer } from 'react-toastify';

import SideModal from '@/components/SideModal/SideModal';
import {
  faCog,
  faPlus,
  faUsers,
  faEnvelope,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { EditUser } from '@/components/UserList/EditUserForm';
import { User } from '@types';
import { Button } from 'react-daisyui';
import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { openHubspotUser } from '@/utils/hubspot';
import { sendLoginToOtherUsers } from '@/utils/auth/auth';
import { TableUsers } from '@/pages/UsersList/TableUsers';
import { LoadingPage } from '@/pages/LoadingPage';
import ErrorAlert from '@/components/ErrorAlert/ErrorAlert';
import { put } from '@/utils/queries';
import {
  alertMessage,
  alertMessageSuccess,
  alertErrorMessage,
} from '@/utils/alerts';
import UserAccordionAlertModal from '@/components/Modal/GenericConfirmationModal';
import SendLoginEmailToInvestorModal from '@/components/Modal/SendLoginEmailToInvestorModal';
import useUsersList from '../../hooks/useUsersList';

function UsersList({ test = false }: { test?: boolean }) {
  const { getUsersList, refreshUsersList } = useUsersList();
  const {
    data: userData,
    isValidating: isLoading,
    error: loadingError,
  } = getUsersList();

  const [users, setUsers] = useState<User[]>([]);
  const [newUser, setNewUser] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User>();
  const [showModal, setShowModal] = useState(false);
  const [modalUser, setModalUser] = useState<User | null>(null);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const userActions = {
    admins: [
      {
        label: 'View and Edit',
        icon: faCog,
        action: (user: User) => setUserAndOpenSideModal(user),
      },
      {
        label: 'Send Login',
        icon: faEnvelope as IconProp,
        action: (user: User) => sendLogin(user?.email_address),
      },
      {
        label: 'Disable User',
        icon: faTrash as IconProp,
        action: (user: User) => onShowModal(user),
      },
    ],
    clients: [
      {
        label: 'Open Hubspot',
        icon: faHubspot as IconProp,
        action: (user: User) => openHubspotUser(user?.hubspot_id || undefined),
      },
      {
        label: 'Send Login',
        icon: faEnvelope as IconProp,
        action: (user: User) => sendLogin(user?.email_address),
      },
    ],
    superAdmins: [
      {
        label: 'View and Edit',
        icon: faCog,
        action: (user: User) => setUserAndOpenSideModal(user),
      },
      {
        label: 'Send Login',
        icon: faEnvelope as IconProp,
        action: (user: User) => sendLogin(user?.email_address),
      },
    ],
    investors: [
      {
        label: 'View and Edit',
        icon: faCog,
        action: (user: User) => setUserAndOpenSideModal(user),
      },
      {
        label: 'Send Login',
        icon: faEnvelope as IconProp,
        action: (user: User) => sendLoginForInvestor(user),
      },
    ],
    disabledUsers: [
      {
        label: 'View and Edit',
        icon: faCog,
        action: (user: User) => setUserAndOpenSideModal(user),
      },
    ],
  };

  const navigate = useNavigate();
  const { userId } = useParams();

  // eslint-disable-next-line consistent-return
  const setUserAndOpenSideModal = (user: User) => {
    if (user.role === 'client') return close();
    setCurrentUser(user);
    setModalOpen(true);
    navigate(`/users/${user.id}/edit-user`);
  };

  useEffect(() => {
    setUsers(userData || []);
    if (userData && userId) {
      const userFound = userData?.filter(
        (user: User) => user.id === Number(userId)
      );
      setUserAndOpenSideModal(userFound[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  if (loadingError) {
    return (
      <PageContainer name='client-list'>
        <DashboardWrapper>
          <ErrorAlert />
        </DashboardWrapper>
      </PageContainer>
    );
  }

  const updateUser = (user: User) => {
    const existingUser = users.find((u) => u.id === user.id);
    if (existingUser) {
      setUsers(
        users.map((u) => {
          if (user.id === u.id) {
            return user;
          }

          return u;
        })
      );
    } else {
      setUsers([...users, user]);
    }

    close();
  };

  const close = () => {
    setModalOpen(false);
    setNewUser(false);
    setCurrentUser(undefined);
    navigate('/users');
  };

  const sendLoginForInvestor = (user: User) => {
    setSelectedUser(user);
    setShowEmailModal(true);
  };

  const sendLogin = async (email: string) => {
    const { error, success } = await sendLoginToOtherUsers({ email });

    if (error) {
      alertErrorMessage('Error sending Login to user');
    }
    if (success) {
      alertMessageSuccess('Login Sent Successfully!');
    }
  };

  const onShowModal = (user: User, show = true) => {
    setShowModal(show);
    if (show) {
      setModalUser(user);
    }
  };

  const disableUser = async (
    id: number | undefined,
    email: string | undefined
  ) => {
    try {
      await put(`users/disable/${id || ''}`, { email });
      alertMessage('top-right', 'User Disabled Successfully!');
      await refreshUsersList();
    } catch (error) {
      alertErrorMessage('Operation failed.');
    } finally {
      setShowModal(false);
    }
  };

  if (!userData && isLoading) {
    return <LoadingPage hasNav />;
  }

  if (!userData?.length) {
    return (
      <PageContainer name='user-management'>
        <DashboardWrapper>
          <EmptyState name='companies' />
        </DashboardWrapper>
      </PageContainer>
    );
  }

  return (
    <PageContainer name='user-management'>
      <DashboardWrapper>
        <SideModal loading={false} open={modalOpen} onClose={close}>
          {currentUser || newUser ? (
            <EditUser
              user={currentUser || undefined}
              onSave={(user: User) => updateUser(user)}
              close={close}
              onDisable={() => onShowModal(currentUser as User)}
            />
          ) : (
            ''
          )}
        </SideModal>
        <UserAccordionAlertModal
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAcceptAction={async () => {
            await disableUser(modalUser?.id, modalUser?.email_address);
          }}
          showModal={showModal}
          onCloseAction={() => setShowModal(false)}
          modalMessage={`Are you sure you want to archive the User ${
            modalUser?.first_name as string
          } 
          ${modalUser?.last_name as string}?`}
        />
        <div className='p-4'>
          <div className='grid grid-cols-2 place-content-between mb-4'>
            <h1 className='text-lg font-bold uppercase'>
              <FontAwesomeIcon
                icon={faUsers}
                size='lg'
                className='mr-4 text-white'
              />
              Users
            </h1>
            <div className='text-lg font-bold uppercase text-right'>
              <Button
                color='accent'
                size='sm'
                onClick={() => {
                  setNewUser(true);
                  setModalOpen(true);
                }}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  size='lg'
                  className='mr-4 text-white'
                />
                New User
              </Button>
            </div>
          </div>
          <ToastContainer />
          <TableUsers users={users} test={test} userActions={userActions} />
          {showEmailModal && selectedUser ? (
            <SendLoginEmailToInvestorModal
              // companyName={companyName}
              companyName=''
              showModal={showEmailModal}
              email={selectedUser.email_address}
              onClose={() => {
                setShowEmailModal(false);
                setSelectedUser(null);
              }}
            />
          ) : null}
        </div>
      </DashboardWrapper>
    </PageContainer>
  );
}

export default UsersList;
export { UsersList };
