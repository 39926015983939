import type { JWTUser, Role, User } from '@types';

export const getUserInitials = (user: JWTUser): string => {
  if (!user?.first_name && !user?.last_name) return '?';
  return `${user.first_name?.[0] ?? ''}${user?.last_name?.[0] ?? ''}`;
};

export const getUserFullName = (user: JWTUser): string => {
  if (!user?.first_name && !user?.last_name) return 'Unknown';
  return `${user.first_name} ${user?.last_name}`;
};

const roleBgColors = {
  superadmin: 'bg-orange-600',
  specialadmin: 'bg-orange-600',
  admin: 'bg-purple-600',
  client: 'bg-primary',
  investor: 'bg-primary',
};

const roleTextColors = {
  superadmin: 'text-orange-600',
  specialadmin: 'text-orange-600',
  admin: 'text-purple-600',
  client: 'text-primary',
  investor: 'text-primary',
};

export const getUserRoleColor = (role: Role) =>
  roleBgColors[role] || 'bg-accent';

export const getUserRoleTextColor = (role: Role) =>
  roleTextColors[role] || 'text-accent';

export const getNewUser = (): User => ({
  id: 0,
  email_address: '',
  first_name: '',
  last_name: '',
  role: 'admin',
  clients: [],
  hubspot_id: '',
  status: 'active',
  primary: false,
});
