import { GenericModal } from '@/components/Modal';
import { Button } from 'react-daisyui';
import { useState, useMemo } from 'react';
import { ToastContainer } from 'react-toastify';
import { Spinner } from '@/components/Spinner';
import type { Folder } from '@types';
import { FilePermissions } from '@/components/FileBrowser/types';
import { getFormDataSignal } from '@/components/FileBrowser/formDataSignal';

interface Props {
  isOpen: boolean;
  folder: Folder;
  onClose: () => void;
  onSave: () => Promise<void>;
}

export default function GrantPermission({
  isOpen,
  folder,
  onClose,
  onSave,
}: Props) {
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const originallyAllowedUsers = useMemo(() => {
    const users = folder.permissions?.find(
      (t) => t.fileId === folder.id
    )?.allowed_users_by_user_name;
    setSelectedUsers(users?.map((u) => u.user_id) || []);
    return users;
  }, [folder]);
  // const tagsAllowdedInFolder = folder.permissions?.find(t => t.fileId === folder.id)?.allowed_tags_by_user_name;

  const addOrRemoveUser = (user: { user_id: number; user_name: string }) => {
    if (selectedUsers.includes(user.user_id)) {
      setSelectedUsers(selectedUsers.filter((u) => u !== user.user_id));
    } else {
      setSelectedUsers([...selectedUsers, user.user_id]);
    }
  };

  const handleShareWithAllUsers = () => {
    if (selectedUsers.length === originallyAllowedUsers?.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(originallyAllowedUsers?.map((u) => u.user_id) || []);
    }
  };

  const saveData = async () => {
    setIsSaving(true);
    const formData = getFormDataSignal() as FormData;
    const permissions: FilePermissions = {
      allowedUsers: selectedUsers.map((u) => u),
      allowedTags:
        folder.permissions?.find((t) => t.fileId === folder.id)?.allowedTags ||
        [],
      parentFolderId: folder.id,
    };
    formData.append('permissions', JSON.stringify(permissions));
    try {
      await onSave();
      setIsSaving(false);
      setSelectedUsers([]);
      onClose();
    } catch (err) {
      setIsSaving(false);
    }
  };

  return (
    <GenericModal isOpen={isOpen} onClose={onClose}>
      <>
        <ToastContainer />
        <div className='flex h-full flex-col'>
          <div className='flex items-center'>
            <div className='w-full'>
              <div className='font-bold text-base'>
                This folder has been shared with external parties
              </div>
              <br />
              <div className='text-base px-4'>
                Would you like to share this file with the same parties who have
                access to the folder?
              </div>
            </div>
          </div>
          <br />

          {originallyAllowedUsers ? (
            <>
              <div className='flex items-start' key='div-main-all-users'>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <label
                  className='flex items-center cursor-pointer'
                  htmlFor='enable-tab'
                  key='label-tab-all-users'
                  onClick={() => handleShareWithAllUsers()}
                >
                  <input
                    type='checkbox'
                    key='checkbox-all-users'
                    data-testid='checkbox-all-users'
                    className='form-checkbox h-5 w-5 text-blue-600'
                    checked={
                      selectedUsers.length === originallyAllowedUsers?.length
                    }
                    onChange={() => handleShareWithAllUsers()}
                  />
                  <h3
                    key='user-name-all-users'
                    className='text-base ml-2 break-all max-w-xs'
                  >
                    Share with all
                  </h3>
                </label>
              </div>
              <br />
              <hr className='w-1/2 self-center' />
              <br />
            </>
          ) : null}

          {originallyAllowedUsers
            ? originallyAllowedUsers.map((user) => (
                <div key={`div-main-${user.user_id}`}>
                  <div
                    className='flex items-start'
                    key={`div-sub-${user.user_id}`}
                    id={`div-sub-${user.user_id}`}
                  >
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                    <label
                      className='flex items-center cursor-pointer'
                      htmlFor='enable-tab'
                      key={`label-tab-${user.user_id}`}
                      id={`label-tab-${user.user_id}`}
                      onClick={() => addOrRemoveUser(user)}
                    >
                      <input
                        type='checkbox'
                        key={`checkbox-${user.user_id}`}
                        data-testid={`checkbox-${user.user_id}`}
                        className='form-checkbox h-5 w-5 text-blue-600'
                        checked={selectedUsers.includes(user.user_id)}
                        onChange={() => addOrRemoveUser(user)}
                      />
                      <h3
                        key={`user-name-${user.user_id}`}
                        id={`user-name-${user.user_id}`}
                        className='text-base ml-2 break-all max-w-xs'
                      >
                        {user.user_name}
                      </h3>
                    </label>
                  </div>
                  <br />
                </div>
              ))
            : null}
          <br />
          <div className='flex justify-center'>
            <Button
              type='submit'
              title='confirm'
              color='accent'
              size='sm'
              className='btn btn-accent'
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={saveData}
            >
              Send
              {isSaving && <Spinner className='mx-auto w-4 text-base ml-4' />}
            </Button>
          </div>
        </div>
      </>
    </GenericModal>
  );
}
