/* eslint-disable */
import swr from 'swr';
import { getJwtUserData, setJwtUserData } from '@/utils/auth';
import moment, { DurationInputArg2 } from 'moment';
import { RefreshAuthResponse } from '@/types';
import * as api from './api';
import { BareFetcher, PublicConfiguration } from 'swr/_internal';

type Endpoint = string | (() => string);

const getEndpoint = (endpoint: Endpoint) =>
  typeof endpoint === 'function' ? endpoint() : endpoint;

const mutateOptions = {
  revalidate: true,
  populateCache: true,
  rollbackOnError: true,
};
export async function fetcher(url: string, retry = true): Promise<any> {
  const { token } = getJwtUserData();

  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    },
    credentials: 'same-origin',
  });
  if (!res.ok) {
    try {
      const json = await res.json();
      const { refreshAuthentication } = json as RefreshAuthResponse;
      if (res.status === 403 && refreshAuthentication && retry) {
        const {
          user,
          access_token: accessToken,
          maxAge,
        } = refreshAuthentication;
        const date = moment().add(maxAge[0], maxAge[1] as DurationInputArg2).toDate();
        setJwtUserData(user, accessToken, date);
        localStorage.setItem('authentication_refreshed', 'true');
        return await fetcher(url, false);
      }
    } catch {
      throw new Error(`Error while fetching ${res.statusText}`);
    }
    throw new Error(`Error while fetching ${res.statusText}`);
  }
  return res.json();
}

const defaultUseSWROptions =  {
  revalidateOnFocus: false,
};

export const get = <T = any>(endpoint: Endpoint, options?: Partial<PublicConfiguration<T, any, BareFetcher<T>>>) => {
  const combinedOptions: Partial<PublicConfiguration<T, any, BareFetcher<T>>> = {
    ...defaultUseSWROptions,
    ...options,
  };
  return swr<T, Error>(getEndpoint(endpoint), (url) => api.get(url), combinedOptions);
}

const mutatingFetch = <T = any>(
  apiFn: (url: string, payload: any) => Promise<T>,
  endpoint: Endpoint,
  payload: any = {},
  mutate?: (...arg: any) => T
) => {
  const updater = () => apiFn(getEndpoint(endpoint), payload);
  return typeof mutate === 'function'
    ? mutate(updater(), { ...mutateOptions, optimisticData: payload })
    : updater();
};

export const post = <T = any>(endpoint: Endpoint, payload: any, mutate?: any) =>
  mutatingFetch<T>(api.post, endpoint, payload, mutate);

export const put = async <T = any>(endpoint: Endpoint, payload: any, mutate?: any) =>
  mutatingFetch<T>(api.put, endpoint, payload, mutate);

export const fetchDelete = <T = any>(endpoint: Endpoint, payload: any, mutate?: any) =>
  mutatingFetch<T>(api.fetchDelete, endpoint, payload, mutate);

/* eslint-enable */
