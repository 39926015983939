import type { Folder, File, FileItem } from '@types';

export type FileSearchItem = Omit<FileItem, 'files'> & {
  path: string;
  linkPath: string;
};

export type FileSelectAction = (file: File | FileSearchItem | null) => void;
export type FolderSelectAction = (
  newFolder: Folder[] | Folder | FileSearchItem
) => string;

export const isFileSearchItem = (
  item: FileSearchItem | FileItem
): item is FileSearchItem => !!(item as FileSearchItem)?.path;

export interface FilePermissions {
  allowedUsers: number[];
  allowedTags: number[];
  parentFolderId: string;
}
