/* eslint-disable import/prefer-default-export */
import InvestorFilesCard from '@/components/FilesCard/InvestorFilesCard';
import MultiStartupSyftDashboard from '@/components/SyftDashboard/MultiStartupSyftDashboard';
import { InvestorAddUserCard } from '@/components/InvestorAddUserCard/InvestorAddUserCard';

export function HomeInvestor() {
  return (
    <>
      <div data-testid='multi-startup-syft-dashboard'>
        <MultiStartupSyftDashboard />
      </div>
      <div className='grid gap-4 lg:grid-cols-2 md:grid-cols-1 p-4'>
        <div key='last-modified-files' data-testid='last-modified-files'>
          <InvestorFilesCard />
        </div>
        <div key='investor-add-users' data-testid='investor-add-users'>
          <InvestorAddUserCard />
        </div>
      </div>
    </>
  );
}
