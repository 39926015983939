import { GenericModal } from '@/components/Modal';
import { get } from '@/utils/queries';
import { EmptyState } from '@/components/EmptyState';
import { Button, Alert } from 'react-daisyui';
import { useState, useMemo } from 'react';
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useRollbar } from '@rollbar/react';
import { User } from '@types';
import { Spinner } from '@/components/Spinner';
import { login } from '@/utils/auth/auth';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';

interface UserValue {
  value: number;
  label: string;
}

interface Props {
  clientId: number;
  isOpen: boolean;
  onClose: () => void;
}

export default function ModalReminder({ clientId, isOpen, onClose }: Props) {
  const rollbar = useRollbar();
  const [selectedUsers, setSelectedUsers] = useState<UserValue[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data: userData, isValidating: isLoading } = get(
    `users/list/${clientId}`
  );

  const options = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      (userData as User[] | undefined)
        ?.filter((user: User) => user.role === 'client')
        .map((user: User) => ({
          value: user.id,
          label: user.email_address,
        })),
    [userData]
  );

  const onSave = async () => {
    setIsSaving(true);
    const subject = 'Opstart - You have new Tasks';
    const type = 'tasks';
    const redirectUrl = `${window.location.host}/transactions`;
    const invalidUserMessage = 'Invalid User';
    let error = false;
    try {
      for (const user of selectedUsers) {
        if (!error) {
          // eslint-disable-next-line no-await-in-loop
          const res = await login(
            user.label.toLowerCase(),
            subject,
            type,
            redirectUrl
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
          if ((res as any).success) {
            rollbar.debug(`Magic link sent to  ${user.label.toLowerCase()}`);
            alertMessageSuccess('Link Sent Successfully');
          } else {
            rollbar.error(
              `ModalReminder.tsx - onSave - Error trying to send the magic link for user ${user.label.toLowerCase()}`,
              {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
                error: (res as any).error,
              }
            );
            alertErrorMessage('Error sending task reminder');
            error = true;
          }
        }
      }
      setIsSaving(false);
      setSelectedUsers([]);
      onClose();
    } catch (err) {
      if ((err as Error).message.includes('Forbidden'))
        alertErrorMessage(invalidUserMessage);
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (!userData.length) {
    return <EmptyState name='clients' />;
  }

  return (
    <GenericModal isOpen={isOpen} onClose={onClose}>
      <>
        <ToastContainer />
        <div className='flex h-full flex-col'>
          <div className='flex items-center'>
            <div className='w-full'>
              <div className='font-bold text-base'>Select Users:</div>
              <br />
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div className='font-bold text-sm text-left pl-3'>
                <Select
                  id='selectuserreminder'
                  isMulti
                  className='react-select-container border-accent'
                  classNamePrefix='react-select'
                  placeholder='select clients ...'
                  closeMenuOnSelect={false}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  options={options}
                  value={selectedUsers}
                  onChange={(value) => setSelectedUsers(value as UserValue[])}
                />
                {!selectedUsers?.length && selectedUsers && (
                  <Alert
                    icon={<ExclamationTriangleIcon className='w-5 h-5' />}
                    className='my-2 text-sm'
                  >
                    Please select at least one user.
                  </Alert>
                )}
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className='flex justify-center'>
            <Button
              type='submit'
              title='login'
              color='accent'
              size='sm'
              className='btn btn-accent'
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={onSave}
              disabled={!selectedUsers?.length}
            >
              Confirm
              {isSaving && <Spinner className='mx-auto w-4 text-base ml-4' />}
            </Button>
          </div>
        </div>
      </>
    </GenericModal>
  );
}
