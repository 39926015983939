import { ReactElement, useState } from 'react';
import { DashboardCard } from '@/components/DashboardCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import useInvestor from '@/hooks/useInvestor';
import { Button, Select } from 'react-daisyui';
import { Client } from '@types';
import AddInvestorModal from '@/components/InvestorAddUserCard/AddInvestorModal';

// eslint-disable-next-line import/prefer-default-export
export function InvestorAddUserCard(): JSX.Element | null {
  const { useStartups } = useInvestor();
  const { data: startups } = useStartups();
  const [company, setCompany] = useState<Client | null>(null);
  const [showModal, setShowModal] = useState(false);

  const { Option } = Select;

  return (
    <DashboardCard data-testid='investor-add-user-card'>
      <div className='flex flex-col md:flex-row'>
        <FontAwesomeIcon
          icon={faUserPlus}
          size='3x'
          className='text-violet-600 mr-2 py-3'
        />
        <div className='text-base grow py-3 min-w-[270px]'>
          <div className='font-bold text-base'>Add a user for a company</div>
          <div className='text-base'>
            <Select
              defaultValue='default'
              className='select select-bordered select-accent w-full mt-4'
              onChange={(selectedCompanyId: number) => {
                setCompany(
                  startups?.find(
                    (startup) => startup.id === Number(selectedCompanyId)
                  ) as Client
                );
              }}
            >
              <Option value='default' disabled>
                Select a company
              </Option>

              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
              {
                (startups as Client[])?.map((client: Client) => (
                  <Option key={String(client.id)} value={String(client.id)}>
                    {client.client_name}
                  </Option>
                )) as unknown as ReactElement
              }
            </Select>
          </div>
          {company?.id ? (
            <div className='mt-2'>
              <Button
                type='submit'
                title='login'
                color='accent'
                size='sm'
                className='btn btn-accent bg-transparentp-2 m-2 ml-0'
                data-testid='add-user-investor-button'
                onClick={() => setShowModal((prev) => !prev)}
              >
                Add User
              </Button>
              {showModal ? (
                <AddInvestorModal
                  company={company}
                  isOpen={showModal}
                  onClose={() => setShowModal(false)}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </DashboardCard>
  );
}
