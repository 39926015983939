import { GenericModal } from '@/components/Modal';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { LoadingCard } from '@/components/DashboardCard';
import PageContainer from '@/components/PageContainer';
import { DashboardWrapper } from '@/components/DashboardWrapper';
import ErrorAlert from '@/components/ErrorAlert/ErrorAlert';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { post } from '@/utils/queries';
import useAccountData from '@/hooks/useAccountData';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { FileItem, User } from '@types';
import useFileData from '@/hooks/useFileData';
import GenericWizard from '../../GenericWizard/GenericWizard';
import CustomTabsStep from './CustomTabsStep';
import FilesStep from './FilesStep';
import SyftDashboardStep from './SyftDashboardStep';
import OverviewStep from './OverviewStep';
import {
  CustomTabItemExtended,
  FileItemExtended,
  SyftItemExtended,
} from './interfaces';
import {
  transformFileItemtoFileItemExtended,
  getOnlySelectedFolders,
  transformCustomTabItemToCustomTabItemExtended,
  getCheckedFileIdRecursive,
  transformSyftTabToSyftTabExtended,
} from './utils';

export default function EditPermissionsModal({
  clientId,
  user,
  isOpen,
  onClose,
}: {
  clientId: number;
  user: User | null;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { auth }: IUserContext = useUserContext();
  const { getAccountData, refreshAccountData } = useAccountData();
  const { getFileData } = useFileData();
  const { data: rootFolder, isValidating: isLoading, error } = getFileData();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { data, error: accountError } = getAccountData(clientId, true);
  const customTabsConfig = (data?.config || []).find(
    (config) => config.config_key === 'custom_tab_options'
  );

  const syftUrl = (data?.config || []).find(
    (config) => config.config_key === 'syft_url'
  );

  const [customTabsState, setCustomTabsState] = useState<
    CustomTabItemExtended[]
  >(() =>
    transformCustomTabItemToCustomTabItemExtended(customTabsConfig, user?.id)
  );

  const [syftUrlTabsState, setSyftUrlTabsState] = useState<SyftItemExtended[]>(
    () => transformSyftTabToSyftTabExtended(syftUrl, user?.id)
  );

  const [filesState, setFilesState] = useState<FileItemExtended | null>(null);

  useEffect(() => {
    if (!isLoading) {
      const clientFolders: FileItem | undefined =
        !isLoading && rootFolder
          ? rootFolder.files.find((e: FileItem) => e.id === data?.ms_team_id)
          : undefined;
      setFilesState(
        clientFolders
          ? transformFileItemtoFileItemExtended(clientFolders, user?.id)
          : null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const updateCustomTabs = (customTabs: CustomTabItemExtended[]) => {
    setCustomTabsState(customTabs);
  };

  const updateSyftUrl = (syftTab: SyftItemExtended[]) => {
    setSyftUrlTabsState(syftTab);
  };

  const updateFiles = (files: FileItemExtended) => {
    setFilesState(files);
  };

  const getSelectedCustomTabs = () =>
    customTabsState.filter((tab) => tab.isChecked);

  const getSelectedSyftTab = () =>
    syftUrlTabsState.filter((tab) => tab.isChecked);

  const getSelectedFolders = () => getOnlySelectedFolders(filesState);

  const savePermissions = () => {
    setIsSaving(true);
    const selectedCustomTabsNames = getSelectedCustomTabs().map(
      (t) => t.titleOfTab
    );

    const selectedSyftUrl = getSelectedSyftTab().map((t) => t.syftUrl);

    const selectedFolderIds: string[] = [];
    if (filesState) {
      getCheckedFileIdRecursive(filesState, selectedFolderIds);
    }

    post<Promise<void>>(`permissions/all`, {
      filesIds: selectedFolderIds,
      syftUrl: selectedSyftUrl,
      customTabNames: selectedCustomTabsNames,
      clientId,
      userIds: [user?.id],
    })
      .then(async () => {
        setIsSaving(false);
        await refreshAccountData(clientId);
        alertMessageSuccess('Permissions updated successfully.');
        onClose();
      })
      .catch(() => {
        alertErrorMessage('Operation failed, please try again later.');
        setIsSaving(false);
      });
  };

  if (error || accountError) {
    return (
      <PageContainer name='client-list'>
        <DashboardWrapper>
          <ErrorAlert
            isSuperAdmin={auth?.isSuperAdmin}
            error={error as unknown}
          />
        </DashboardWrapper>
      </PageContainer>
    );
  }

  if (!isOpen) {
    return <LoadingCard />;
  }

  return (
    <GenericModal isOpen={isOpen} onClose={onClose} isFullWidth={false}>
      <>
        <ToastContainer />
        <GenericWizard disableStep={isSaving}>
          <FilesStep
            user={user as User}
            folderList={filesState as FileItemExtended}
            updateFiles={updateFiles}
            title='Files'
            isLoading={isLoading}
          />
          <SyftDashboardStep
            syftDashboardOptions={syftUrlTabsState}
            updateSyftDashboardOptions={updateSyftUrl}
            title='Dashboard'
          />
          <CustomTabsStep
            customTabs={customTabsState}
            user={user as User}
            updateCustomTabs={updateCustomTabs}
            title='Custom Tabs'
          />
          <OverviewStep
            customTabs={getSelectedCustomTabs()}
            folderList={getSelectedFolders().files}
            syftDashboardOption={getSelectedSyftTab()}
            isSaving={isSaving}
            title='Overview'
            onSave={savePermissions}
          />
        </GenericWizard>
      </>
    </GenericModal>
  );
}
