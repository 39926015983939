import { useState, useMemo, useEffect } from 'react';
import { Button } from 'react-daisyui';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { mutate } from 'swr';
import {
  faDesktop,
  faEye,
  faFolderOpen,
  faBriefcase,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import { AccountDataSection, Client, QboInvoices } from '@types';
import PageContainer from '@/components/PageContainer';
import { DashboardWrapper } from '@/components/DashboardWrapper';
import { AccountDetailsLoader } from '@/components/AccountDetails';
import { EmptyState } from '@/components/EmptyState';
import { get } from '@/utils/queries';
import { get as getApi } from '@/utils/api';
import SideModal from '@/components/SideModal/SideModal';
import { slugify } from '@/utils/string';

import { openHubspotClient } from '@/utils/hubspot';
import { LoadingPage } from '@/pages/LoadingPage';
import ErrorAlert from '@/components/ErrorAlert/ErrorAlert';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { alertMessage } from '@/utils/alerts';
import { ClientManagement } from './ClientPageManagement';

// eslint-disable-next-line import/prefer-default-export
export function ClientList({ test = false }: { test?: boolean }) {
  const accountListAPIUrl = 'account/list';
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    data: companyData, // eslint-disable-line @typescript-eslint/no-unsafe-assignment
    isValidating: isLoading, // eslint-disable-line @typescript-eslint/no-unsafe-assignment
    error, // eslint-disable-line @typescript-eslint/no-unsafe-assignment
  } = get(accountListAPIUrl);

  const {
    data: invoicesData, // eslint-disable-line @typescript-eslint/no-unsafe-assignment
    isValidating: isInvoicesLoading, // eslint-disable-line @typescript-eslint/no-unsafe-assignment
    error: invoicesError, // eslint-disable-line @typescript-eslint/no-unsafe-assignment
  } = get<QboInvoices[]>('qbo/invoices');

  const refreshAccountData = async () => {
    await mutate(accountListAPIUrl);
  };

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const {
    auth,
    setimpersonatingAccountId,
    setCompanyName,
    clearImpersonationSession,
  }: IUserContext = useUserContext(); // Use the context
  const navigate = useNavigate();
  const { clientId, tab } = useParams();
  const [updatingInProgress, setUpdatingInProgress] = useState<boolean>(false);

  const openFiles = (client: Client) => {
    const linkValue = client.hubspot_id
      ? client.hubspot_id
      : slugify(client.client_name);
    navigate(`/files/${linkValue}`);
  };

  const openHome = (paramClientId: number, companyName: string) => {
    if (setimpersonatingAccountId) {
      localStorage.setItem('impersonatingAccountId', paramClientId.toString());
      setimpersonatingAccountId(paramClientId);
    }
    if (setCompanyName) {
      localStorage.setItem('companyName', companyName);
      setCompanyName(companyName);
    }
    navigate('/home');
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const currentCompany: Client = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      companyData?.find((company: Client) => String(company.id) === clientId) ??
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      companyData?.[0] ??
      null,
    [companyData, clientId]
  );

  const setAccountAndOpenSideModal = (accountId: number) => {
    navigate(`/clients/${accountId}/business_info`);
  };

  const refreshHubspotData = async () => {
    setUpdatingInProgress(true);

    try {
      alertMessage(
        'bottom-center',
        'Syncing in progress. Please wait a few minutes and then refresh the page.'
      );
      await getApi('clients/syncHubspot');
      await refreshAccountData();
    } finally {
      setUpdatingInProgress(false);
    }
  };

  useEffect(() => {
    if (clientId) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [clientId]);

  useEffect(() => {
    if (clearImpersonationSession) clearImpersonationSession();
  }, [clearImpersonationSession]);

  const tableActions = [
    {
      label: 'View Details',
      icon: faEye,
      action: (client: Client) => setAccountAndOpenSideModal(client.id),
    },
    {
      label: 'Files',
      icon: faFolderOpen,
      action: (client: Client) => openFiles(client),
    },
    {
      label: 'View As',
      icon: faDesktop,
      action: (client: Client) => openHome(client.id, client.client_name),
    },
    {
      label: 'Open Hubspot',
      icon: faHubspot as IconProp,
      action: (client: Client) => openHubspotClient(client?.hubspot_id),
    },
  ];
  if (error || invoicesError) {
    return (
      <PageContainer name='client-list'>
        <DashboardWrapper>
          <ErrorAlert
            isSuperAdmin={auth?.isSuperAdmin}
            error={error as unknown}
          />
        </DashboardWrapper>
      </PageContainer>
    );
  }

  if ((!companyData && isLoading) || (!invoicesData && isInvoicesLoading)) {
    return <LoadingPage hasNav />;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (!companyData?.length) {
    return (
      <PageContainer name='client-list'>
        <DashboardWrapper>
          <EmptyState name='companies' />
        </DashboardWrapper>
      </PageContainer>
    );
  }

  return (
    <PageContainer name='client-list'>
      <DashboardWrapper>
        <SideModal
          loading={isLoading}
          open={modalOpen && clientId !== undefined}
          onClose={() => {
            navigate('/clients');
          }}
          actionButtons={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            currentCompany && auth?.isAdmin ? (
              <>
                {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access */}
                <Button
                  onClick={() =>
                    openHome(currentCompany.id, currentCompany.client_name)
                  }
                  color='accent'
                  variant='outline'
                  className='ml-4'
                  size='sm'
                  startIcon={<FontAwesomeIcon icon={faDesktop} />}
                >
                  View As
                </Button>
                {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument */}
                <Link
                  to={`/files/${
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions,  @typescript-eslint/no-unsafe-member-access
                    currentCompany?.hubspot_id
                      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        currentCompany?.hubspot_id
                      : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
                        slugify(currentCompany?.client_name)
                  }`}
                >
                  <Button
                    color='accent'
                    className='ml-4'
                    size='sm'
                    startIcon={<FontAwesomeIcon icon={faFolderOpen} />}
                  >
                    Files
                  </Button>
                </Link>
              </>
            ) : undefined
          }
        >
          {clientId ? (
            <AccountDetailsLoader
              clientId={clientId as unknown as number}
              test={test}
              tab={tab as AccountDataSection}
              onTabChange={(tabName) =>
                navigate(`/clients/${clientId}/${tabName}`)
              }
            />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          )}
        </SideModal>
        <div className='p-4'>
          <div className='flex w-full justify-between items-center mb-4'>
            <h1 className='text-lg font-bold uppercase'>
              <FontAwesomeIcon
                icon={faBriefcase}
                size='lg'
                className='mr-4 text-white'
              />
              Clients
            </h1>
            <Button
              color='accent'
              id='refresh'
              size='sm'
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={refreshHubspotData}
            >
              <FontAwesomeIcon
                icon={faRefresh}
                size='lg'
                className={`mr-2 text-white ${
                  updatingInProgress ? 'spinning' : ''
                }`}
              />
              Refresh
            </Button>
          </div>
          <ClientManagement
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            clients={companyData || []}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            invoices={invoicesData || []}
            test={test}
            actions={tableActions}
          />
        </div>
      </DashboardWrapper>
    </PageContainer>
  );
}
