import { DashboardCard } from '@/components/DashboardCard';
import { Checkbox, Input } from 'react-daisyui';
import Select from 'react-select';
import { useEffect, useMemo, useState } from 'react';
import { MenuAction, Sort, TableSort } from '@/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Client, ClientProperty, QboInvoices } from '@types';
import moment from 'moment';
import { getNewSortState, getNewSortStateTable } from '@/utils/tableSort';
import {
  handleFinancialDeliveryDateDeal,
  handleController,
} from '@/utils/dealUtils';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { TableClients } from './components/TableClients';
import { TableAR, TableARData } from './components/TableAR';

interface Props {
  clients: Client[];
  invoices: QboInvoices[];
  // eslint-disable-next-line react/no-unused-prop-types
  test?: boolean;
  actions: MenuAction[];
}

const tableOptions = [
  { value: 'Clients', label: 'Clients' },
  { value: 'AR', label: 'AR' },
];

const sortData = { field: 'action', asc: true };

export function ClientManagement({ clients, actions, invoices }: Props) {
  const { auth }: IUserContext = useUserContext();

  const [clientTableSort, setClientTableSort] = useState<Sort>(sortData);
  const [invoiceSort, setInvoiceSort] = useState<TableSort<TableARData>>({
    field: 'action' as keyof TableARData,
    asc: true,
  });
  const [searchText, setSearchText] = useState<string>('');
  const [showChurned, setShowChurned] = useState<boolean>(false);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(40);
  const [selectedTable, setSelectedTable] = useState<string>('Clients');

  const countActiveUsers = (client: Client) => {
    return (
      client?.users?.filter(
        (user) => user.role === 'client' && user.status === 'active'
      ).length || 0
    );
  };

  const hasSyftUrl = (client: Client) => {
    return JSON.stringify(client?.config)?.match('syft_url')?.length || 0;
  };

  const hasQbo = (client: Client) => {
    return client?.qbo_companies ? client?.qbo_companies?.length > 0 : false;
  };

  const getSortValue = (sortField: string, client: Client) => {
    switch (sortField) {
      case 'status':
        return client?.client_profiles?.status || 0;
      case 'latest_pdf_date':
      case 'latest_excel_date':
        return client?.client_profiles?.[sortField]
          ? new Date(client?.client_profiles?.[sortField] as string).getTime()
          : 0;
      case 'qbo_transactions':
        return (
          client.qbo_companies?.reduce(
            (acc, obj) => acc + obj.pending_transactions,
            0
          ) || 0
        );
      case 'syft':
        return hasSyftUrl(client);
      case 'qbo':
        return hasQbo(client);
      case 'active_users':
        return countActiveUsers(client) || 0;
      case 'controllers':
        return handleController(client.hubspot_data?.detailedData?.plan);
      case 'financial_delivery_date':
        return handleFinancialDeliveryDateDeal(
          client.hubspot_data?.detailedData?.plan
        );
      default:
        return client[sortField as ClientProperty];
    }
  };

  const getSortValueInvoice = (sortField: string, invoice: QboInvoices) => {
    switch (sortField) {
      case 'client_name':
        return invoice.qbo_customer?.client?.client_name;
      case 'balance':
        return Number(invoice.balance);
      case 'controller':
        return handleController(
          invoice.qbo_customer?.client?.hubspot_data?.detailedData?.plan
        );
      default:
        return invoice[sortField as keyof QboInvoices];
    }
  };

  const sortInvoices = (): QboInvoices[] => {
    return [...invoices].sort((a, b) => {
      const aValue = getSortValueInvoice(invoiceSort.field, a) ?? '';
      const bValue = getSortValueInvoice(invoiceSort.field, b) ?? '';

      if (aValue > bValue) return invoiceSort.asc ? 1 : -1;
      if (aValue < bValue) return invoiceSort.asc ? -1 : 1;
      return 0;
    });
  };

  const sortClients = (): Client[] =>
    clients
      .sort((c1, c2) => {
        const obj1 = getSortValue(clientTableSort.field, c1);
        const obj2 = getSortValue(clientTableSort.field, c2);
        if (obj1?.toString() && obj2?.toString() && obj1 > obj2)
          return clientTableSort.asc ? 1 : -1;
        return clientTableSort.asc ? -1 : 1;
      })
      .filter((client) => {
        const controller = handleController(
          client.hubspot_data?.detailedData?.plan
        );
        return (
          controller?.toLowerCase().match(searchText.toLowerCase()) ||
          client.client_name.toLowerCase().match(searchText.toLowerCase())
        );
      })
      .filter((client) => {
        if (!showChurned) {
          return client.deal_status === 'closedwon';
        }
        return ['closedwon', 'churned'].includes(client.deal_status as string);
      });

  const filteredInvoices = (): QboInvoices[] =>
    sortInvoices().filter((invoice) => {
      const client = invoice.qbo_customer?.client;
      const controller = handleController(
        invoice.qbo_customer?.client?.hubspot_data?.detailedData?.plan
      );
      const clientNameMatch = client?.client_name
        .toLowerCase()
        .includes(searchText.toLowerCase());

      return (
        (controller?.toLowerCase().includes(searchText.toLowerCase()) ||
          clientNameMatch) &&
        (showChurned || client?.deal_status !== 'churned')
      );
    });

  const sortBy = (fieldName: string) => {
    const newSort = getNewSortState(fieldName, clientTableSort);
    setClientTableSort(newSort);
  };

  // This function is temporary and will be removed once the TableClients has been refactored to use the new Table component
  const sortByInvoices = (fieldName: keyof TableARData) => {
    const newSort = getNewSortStateTable(fieldName, invoiceSort);
    setInvoiceSort(newSort);
  };

  const sortedClientRecords = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    () => sortClients(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientTableSort, selectedPage, searchText, showChurned, clients]
  );

  const sortedInvoicesRecords = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    () => filteredInvoices(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invoiceSort, searchText, showChurned, invoices]
  );

  useEffect(() => {
    setSelectedPage(1);
  }, [clientTableSort, recordsPerPage, searchText, showChurned]);

  const formatDate = (date?: string) => {
    if (date) {
      return moment(new Date(date)).format('MM/DD/YYYY');
    }
    return ' - ';
  };

  const formatString = (string?: string) => {
    if (!string) return null;
    return string.length > 6
      ? ` - ${string.substring(0, 6)}...`
      : ` - ${string}`;
  };

  const handleFinancialDeliveryDate = (
    financialDate: Date | undefined,
    fileDate: string | undefined
  ): boolean | undefined => {
    if (!financialDate || !fileDate) return;
    const formattedFinancialDate = moment(new Date(financialDate));
    const formattedFileDate = moment(fileDate);

    if (formattedFinancialDate.isAfter(formattedFileDate)) {
      // eslint-disable-next-line consistent-return
      return true;
    }
    // eslint-disable-next-line consistent-return
    return false;
  };

  const showDeliveryDateColumn =
    auth?.isSuperAdmin || auth?.isAdmin || auth?.isSpecialAdmin;

  return (
    <DashboardCard className='p-4' cardBodyclassName='p-0' hasMininumWidth>
      <div className='mt-3 mb-1'>
        <div className='relative text-gray-600 focus-within:text-gray-400'>
          <span className='absolute inset-y-0 left-[260px] flex items-center pl-2'>
            <button
              type='submit'
              className='p-1 focus:outline-none focus:shadow-outline'
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </span>
          <Input
            data-testid='client-search-input'
            className='border-accent w-[300px]'
            placeholder='Search Clients'
            size='sm'
            defaultValue={searchText}
            onChange={(event) => setSearchText(event.target.value)}
          />
          <span className='ml-10 text-white'>Show churned clients</span>
          <Checkbox
            checked={showChurned}
            data-testid='showChurned'
            className='border-accent relative top-[7px] ml-2'
            onChange={() => setShowChurned(!showChurned)}
          />
          <span className='text-white ml-10'>
            Total {selectedTable === 'Clients' ? 'Clients' : 'Invoices'}:{' '}
            <strong>
              {selectedTable === 'Clients'
                ? sortedClientRecords.length
                : sortedInvoicesRecords.length}
            </strong>
          </span>
          <span className='ml-10 text-white'>Table: </span>
          <div className='inline-flex'>
            <Select
              className='react-select-container border-accent'
              options={tableOptions}
              value={tableOptions.find(
                (option) => option.value === selectedTable
              )}
              onChange={(selectedOption) =>
                setSelectedTable(selectedOption?.value || 'Clients')
              }
              styles={{
                control: (provided) => ({
                  ...provided,
                  minWidth: 150,
                  color: 'black',
                  backgroundColor: 'black',
                  border: '1px solid #00a089',
                }),
                menu: (provided) => ({
                  ...provided,
                  color: 'black',
                  backgroundColor: 'black',
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: 'white',
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected ? 'black' : 'white',
                  backgroundColor: state.isSelected ? 'white' : 'black',
                }),
              }}
            />
          </div>
        </div>
      </div>

      {selectedTable === 'Clients' && (
        <TableClients
          sortedClientRecords={sortedClientRecords}
          actions={actions}
          sort={clientTableSort}
          sortBy={sortBy}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          recordsPerPage={recordsPerPage}
          setRecordsPerPage={setRecordsPerPage}
          handleFinancialDeliveryDate={handleFinancialDeliveryDate}
          formatDate={formatDate}
          formatString={formatString}
          countActiveUsers={countActiveUsers}
          hasSyftUrl={hasSyftUrl}
          hasQbo={hasQbo}
          handleController={handleController}
          showDeliveryDateColumn={showDeliveryDateColumn}
        />
      )}

      {selectedTable === 'AR' && (
        <TableAR
          invoices={sortedInvoicesRecords}
          sort={invoiceSort}
          sortBy={sortByInvoices}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          recordsPerPage={recordsPerPage}
          setRecordsPerPage={setRecordsPerPage}
          handleController={handleController}
        />
      )}
    </DashboardCard>
  );
}
