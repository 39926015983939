/* eslint-disable @typescript-eslint/no-use-before-define */
import { DashboardCard } from '@/components/DashboardCard';
import { Input } from 'react-daisyui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useCallback } from 'react';
import { TableSort } from '@/types';
import type { EventLog } from '@types';
import { debounce } from 'lodash';
import { Spinner } from '@/components/Spinner';
import { getNewSortStateTable } from '@/utils/tableSort';
import EventsTable from './EventsTable';
import { EventLogsTableData } from './eventsTable.util';
import { apiFieldMapping } from '../../../../../../shared/utils/logs';

interface Props {
  logData: EventLog[];
  sort: TableSort<EventLogsTableData>;
  searchText: string;
  selectedPage: number;
  recordsPerPage: number;
  setSort: (sort: TableSort<EventLogsTableData>) => void;
  setSelectedPage: (page: number) => void;
  setRecordsPerPage: (records: number) => void;
  setSearchText: (str: string) => void;
  isLoading: boolean;
}
function UserEventLogs({
  logData = [],
  sort,
  searchText,
  selectedPage,
  recordsPerPage,
  setSort,
  setSelectedPage,
  setRecordsPerPage,
  setSearchText,
  isLoading,
}: Props) {
  const sortBy = (fieldName: keyof EventLogsTableData) => {
    const apiField = apiFieldMapping[fieldName as string] || fieldName;
    const newSort = getNewSortStateTable(
      apiField as keyof EventLogsTableData,
      sort
    );
    setSort(newSort);
  };

  useEffect(() => {
    setSelectedPage(1);
  }, [sort, recordsPerPage, searchText, setSelectedPage]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = useCallback(
    debounce((str: string) => {
      setSearchText(str);
    }, 500),
    []
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <DashboardCard className='mt-4 p-0' cardBodyclassName='p-0'>
      <div className='mt-3 ml-2 mb-1'>
        <div className='relative text-gray-600 focus-within:text-gray-400'>
          <span className='absolute inset-y-0 left-[260px] flex items-center pl-2'>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <button
              type='submit'
              className='p-1 focus:outline-none focus:shadow-outline'
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </span>
          <Input
            data-testid='logs-search-input'
            className='border-accent w-[300px]'
            placeholder='Search Title'
            size='sm'
            defaultValue={searchText}
            onChange={(event) => search(event.target.value)}
          />
        </div>
      </div>
      {/* eslint-disable-next-line  @typescript-eslint/no-unsafe-member-access */}
      {logData.length > 0 ? (
        <div className='pl-0 pr-4'>
          <EventsTable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            records={logData}
            sortBy={sortBy}
            sort={sort}
            selectedPage={selectedPage}
            recordsPerPage={recordsPerPage}
            onPageSelect={setSelectedPage}
            onSelectRowsChange={setRecordsPerPage}
          />
        </div>
      ) : (
        <div className='text-center'>No logs Found</div>
      )}
    </DashboardCard>
  );
}

export default UserEventLogs;
