import { LoadingCard } from '@/components/DashboardCard';
import SyftDashboard from '@/components/SyftDashboard/SyftDashboard';
import useInvestor from '@/hooks/useInvestor';
import useStorageData from '@/hooks/useStorageData';
import Select, { OnChangeValue } from 'react-select';
import { useEffect, useMemo, useState } from 'react';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { safeParseJson } from '@/components/AccountDetails/utils';
import { Client, SyftProperties } from '@types';

interface SelectedStartups {
  label: string;
  value: number;
}

function MultiStartupSyftDashboard() {
  const { auth }: IUserContext = useUserContext(); // Use the conte
  const { useStartups } = useInvestor();
  const { getSyftDashboardStorageData, saveSyftDashboardStorageData } =
    useStorageData();
  const { data, isLoading, error } = useStartups();
  const [selectedStartups, setSelectedStartups] = useState<
    readonly SelectedStartups[]
  >([]);
  const startupOptions = useMemo(
    () =>
      data
        ?.filter(
          (startup) =>
            auth?.isSuperAdmin || auth?.authorizedClientIds.includes(startup.id)
        )
        .map((client: Client) => ({
          label: client.client_name,
          value: client.id,
        })),
    [data, auth]
  );

  useEffect(() => {
    if (data && !isLoading) {
      const localStorageData = getSyftDashboardStorageData(
        auth?.email_address as string
      );
      let startupSelections: SelectedStartups[] = [];
      if (localStorageData) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const localStorageDataObject: {
          label: string;
          value: number;
        }[] = JSON.parse(localStorageData);
        let dataModified = false;
        localStorageDataObject.forEach((storageData, index) => {
          if (!data.find((client) => client.id === storageData.value)) {
            localStorageDataObject.splice(index, 1);
            dataModified = true;
          }
        });

        if (dataModified)
          saveSyftDashboardStorageData(
            auth?.email_address as string,
            localStorageDataObject
          );

        startupSelections =
          localStorageDataObject.length > 0
            ? localStorageDataObject
            : startupOptions || [];
      } else {
        startupSelections = startupOptions || [];
      }

      setSelectedStartups(startupSelections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) return <LoadingCard />;
  if (error) return <div>Failed to load</div>;
  if (!data) return <div>No data</div>;
  const onChange = (
    e: OnChangeValue<{ label: string; value: number }, true>
  ) => {
    saveSyftDashboardStorageData(auth?.email_address as string, e);
    setSelectedStartups(e);
  };
  return (
    <div>
      <Select
        isMulti
        className='react-select-container border-accent w-1/3 mt-4 ml-4'
        classNamePrefix='react-select'
        placeholder='select dashboards...'
        noOptionsMessage={() => 'No Dashboards'}
        closeMenuOnSelect={false}
        options={startupOptions}
        value={selectedStartups}
        onChange={onChange}
      />
      {data
        ?.filter((client) =>
          selectedStartups?.some((s) => s.value === client.id)
        )
        .map((client) => {
          const clientName = client.client_name;
          const syftUrlArray = client.config
            ?.filter(({ config_key }) => config_key === 'syft_url')
            .map(({ config_value }) => config_value);
          const syftUrlOptions = syftUrlArray?.length ? syftUrlArray[0] : '';
          const parseSyftUrlOptions = safeParseJson(
            syftUrlOptions,
            []
          ) as SyftProperties[];

          const syftUrlToShow = parseSyftUrlOptions.find((option) =>
            option.allowedUsers.includes(Number(auth?.id))
          )?.syftUrl;

          return syftUrlToShow ? (
            <SyftDashboard
              syftUrl={syftUrlToShow}
              title={clientName}
              key={clientName}
            />
          ) : null;
        })}
    </div>
  );
}

export default MultiStartupSyftDashboard;
