import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import {
  MENU_ADMIN_EXCLUSIVES,
  MENU_BY_USER,
  getIconDefinitionByName,
} from '@/components/Nav/utils';
import NavItemsList from '@/components/Nav/NavItemsList';
import { IUserContext, useUserContext } from '@/context/UserContext';
import useAccountData from '@/hooks/useAccountData';
import { extractCustomTabsFromConfig } from '@/utils/auth';

interface NavItemsProps {
  isMobile?: boolean;
}
function NavItems({ isMobile = false }: NavItemsProps) {
  const { auth, impersonatingAccountId }: IUserContext = useUserContext();
  const { getAccountData } = useAccountData();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, no-nested-ternary
  const { data } = impersonatingAccountId
    ? getAccountData(impersonatingAccountId)
    : auth?.clients?.[0]?.id
    ? getAccountData(auth?.clients?.[0]?.id)
    : { data: null };
  const customTabs = extractCustomTabsFromConfig(data?.config || []);
  const menuType = () => {
    if (impersonatingAccountId) {
      return 'client';
    }

    return auth?.role || 'client';
  };
  const menuOptions = [
    ...MENU_BY_USER[menuType()],
    ...(impersonatingAccountId ? MENU_ADMIN_EXCLUSIVES : []),
    ...customTabs.map((customTab) => ({
      icon: getIconDefinitionByName(customTab.icon) as IconProp,
      label: customTab.titleOfTab,
      url: `/custom-tab-option/${customTab.titleOfTab}?embeddedLink=${customTab.embeddedLink}
        &documentLink=${customTab.documentLink}`,
    })),
    ...[
      {
        icon: faRightFromBracket,
        label: 'Logout',
        url: '/logout',
        className: 'md:hidden',
      },
    ],
  ];
  return <NavItemsList options={menuOptions} isMobile={isMobile} />;
}

export default NavItems;
