/* istanbul ignore file */
import { BrowserRouter as Router } from 'react-router-dom';
import { Theme } from 'react-daisyui';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import '@/css/App.css';
import usePendo from '@/hooks/usePendo';
import { UserProvider } from '@/context/UserContext';
import { BugReportButton } from './components/BugReport';

import Routes from './Router';

export default function App() {
  const pendo = usePendo();

  return (
    <UserProvider>
      <HelmetProvider>
        {pendo.isInstalled() && (
          <Helmet>
            <script>{pendo.script}</script>
          </Helmet>
        )}
        <Theme dataTheme='opstart'>
          <Router>
            <Routes />
          </Router>
          {/* eslint-disable-next-line max-len */}
          <div className='fixed z-90 bottom-10 right-8 drop-shadow-lg flex justify-center items-centertext-4x hover:animate-bounce duration-300'>
            <BugReportButton />
          </div>
        </Theme>
      </HelmetProvider>
    </UserProvider>
  );
}
