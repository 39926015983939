import { fetcher } from '@/utils/queries';
import useSWR from 'swr';
import { Client } from '../../../shared/types/client';

// generate a custom hook for querying /api/investors/getStartups by investorid
const useInvestor = () => {
  const useStartups = () =>
    useSWR<Client[], Error>(`/api/investors/getStartups`, fetcher);

  return {
    useStartups,
  };
};
export default useInvestor;
