import { GenericModal } from '@/components/Modal';
import { Button, Textarea } from 'react-daisyui';
import { useState } from 'react';
import { useRollbar } from '@rollbar/react';
import { Spinner } from '@/components/Spinner';
import { loginGrantingFilePermissions } from '@/utils/auth/auth';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import type { JWTUser } from '@types';

export interface UserValue {
  value: number;
  label: string;
  email: string;
}

interface Props {
  auth: JWTUser;
  currentPath: string;
  currentFolderIdPath: string;
  clientId: number;
  isOpen: boolean;
  name: string;
  onClose: () => void;
  selectedUsers: UserValue[];
  setSelectedUsers: (user: UserValue[]) => void;
}

export default function SharePermission({
  auth,
  isOpen,
  onClose,
  currentPath,
  currentFolderIdPath,
  clientId,
  selectedUsers,
  name,
  setSelectedUsers,
}: Props) {
  const rollbar = useRollbar();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [content, setContent] = useState(
    // eslint-disable-next-line max-len
    `${auth.first_name} ${auth.last_name} has shared ${name} with you via OpStart. Click the link below to login and view.`
  );
  const handleContentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setContent(event.target.value);
  };

  const onSendNotifications = async () => {
    setIsSaving(true);
    const subject = `${auth.first_name} ${auth.last_name} has shared a folder with you`;
    const type = 'shareFolder';
    const redirectUrl = `${window.location.origin}/files/${currentPath}`;

    const invalidUserMessage = 'Invalid User';
    let error = false;
    const pathIds = currentFolderIdPath.split('/');

    try {
      for (const user of selectedUsers) {
        if (!error) {
          // eslint-disable-next-line no-await-in-loop
          const res = await loginGrantingFilePermissions(
            user.email.toLowerCase(),
            subject,
            type,
            clientId,
            pathIds,
            redirectUrl,
            content
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
          if ((res as any).success) {
            rollbar.debug(`Magic link sent to  ${user.label.toLowerCase()}`);
            alertMessageSuccess('Link Sent Successfully');
          } else {
            rollbar.error(
              `ShareFolder.tsx - onSave - Error trying to send the magic link for user ${user.label.toLowerCase()}`,
              {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
                error: (res as any).error,
              }
            );
            alertErrorMessage('Error sending task reminder');
            error = true;
          }
        }
      }

      setIsSaving(false);
      setSelectedUsers([]);
      onClose();
    } catch (err) {
      if ((err as Error).message.includes('Forbidden'))
        alertErrorMessage(invalidUserMessage);
      setIsSaving(false);
    }
  };

  return (
    <GenericModal isOpen={isOpen} onClose={onClose}>
      <div className='flex h-full flex-col'>
        <div className='flex items-center'>
          <div className='w-full'>
            <div className='font-bold text-base'>Send Email Notification</div>
            <br />
            <div className='font-bold text-base'>Email Content:</div>
            <Textarea
              id='message-founders'
              className='w-full input-accent p-2 rounded-md mt-4 text-left'
              rows={15}
              cols={20}
              placeholder='Content'
              value={content}
              onChange={handleContentChange}
            />
          </div>
        </div>
        <br />
        <br />
        <div className='flex justify-end'>
          <Button
            title="don't send"
            color='ghost'
            size='sm'
            className='mr-4'
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onClose}
            data-testid='dont-send-notification'
          >
            {`Don't Send`}
            {isSaving && <Spinner className='mx-auto w-4 text-base ml-4' />}
          </Button>
          <Button
            type='submit'
            title='send notification'
            color='accent'
            size='sm'
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSendNotifications}
            data-testid='send-notification'
          >
            Send Notification
            {isSaving && <Spinner className='mx-auto w-4 text-base ml-4' />}
          </Button>
        </div>
      </div>
    </GenericModal>
  );
}
