import { GenericModal } from '@/components/Modal';
import { Button } from 'react-daisyui';
import { useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquare,
  faSquareMinus,
  faSquareCheck,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { Spinner } from '@/components/Spinner';
import { get } from '@/utils/queries';
import type { FileItem, Folder, User } from '@types';
import { FileSearchItem } from '@/components/FileBrowser/types';
import { Auth } from '@/types';
import { UserValue } from '@/components/FileBrowser/SharePermission';

interface Props {
  auth: Auth | null | undefined;
  isOpen: boolean;
  clientId: number;
  folderOrFile: Folder | FileSearchItem | FileItem;
  onClose: () => void;
  onSave: (usersToAdd: UserValue[], usersToRemove: UserValue[]) => void;
}

export default function GrantPermissionModal({
  auth,
  isOpen,
  clientId,
  folderOrFile,
  onClose,
  onSave,
}: Props) {
  const originallyAllowedUsers =
    folderOrFile.permissions
      ?.find((t) => t.fileId === folderOrFile.id)
      ?.allowed_users_by_user_name?.map((u) => u.user_id) || [];

  const [selectedUsers, setSelectedUsers] = useState<number[]>(
    originallyAllowedUsers
  );

  const [removedUsers, setRemovedUsers] = useState<number[]>([]);

  const { data: userData, isValidating: isLoading } = get<User[]>(
    `users/list/${clientId}`
  );

  const investorList = useMemo(() => {
    if (!auth || !userData) return [];

    const roleToFilterBy = 'investor';

    return userData
      .filter(({ role, enabled }) => role === roleToFilterBy && enabled)
      .map(({ id, first_name, last_name, email_address }) => ({
        id,
        label: `${first_name} ${last_name}`,
        email: email_address,
      }));
  }, [userData, auth]);

  const toggleUserSelection = (userId: number) => {
    if (
      originallyAllowedUsers.includes(userId) &&
      !removedUsers.includes(userId)
    ) {
      setRemovedUsers([...removedUsers, userId]);
    }

    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.includes(userId)
        ? prevSelectedUsers.filter((id) => id !== userId)
        : [...prevSelectedUsers, userId]
    );
  };

  const handleShareWithAllUsers = () => {
    if (selectedUsers.length === investorList?.length) {
      setSelectedUsers([]);
      setRemovedUsers(originallyAllowedUsers);
    } else {
      setSelectedUsers(investorList?.map(({ id }) => id) || []);
      setRemovedUsers([]);
    }
  };

  const saveData = () => {
    const usersToAdd: UserValue[] = investorList
      .filter(
        ({ id }) =>
          selectedUsers.includes(id) && !originallyAllowedUsers.includes(id)
      )
      .map(({ id, label, email }) => ({
        value: id,
        label,
        email,
      }));

    const usersToRemove: UserValue[] = investorList
      .filter(
        ({ id }) =>
          !selectedUsers.includes(id) && originallyAllowedUsers.includes(id)
      )
      .map(({ id, label, email }) => ({
        value: id,
        label,
        email,
      }));

    onSave(usersToAdd, usersToRemove);
  };

  const hasPartialPermissions = (
    files: (Folder | FileItem)[],
    userId: number,
    onlyChecked: boolean
  ): number => {
    const totalFiles = files.filter(
      (sf) =>
        !onlyChecked ||
        sf.permissions?.some((p) =>
          p.allowed_users_by_user_name.some(
            (u) => !onlyChecked || u.user_id === userId
          )
        )
    ).length;
    const descendantsChecked = (files || [])
      .map((f) => hasPartialPermissions(f.files || [], userId, onlyChecked))
      .reduce((total, amount) => total + amount, 0);
    return totalFiles + descendantsChecked;
  };

  const getUserPermissionIcon = (userId: number): IconDefinition => {
    const isSelected = selectedUsers.includes(userId);
    const isRemoved = removedUsers.includes(userId);
    const isOriginallyAllowed = originallyAllowedUsers.includes(userId);

    if (!isSelected) {
      return faSquare;
    }

    if (!isRemoved && !isOriginallyAllowed) {
      if ((folderOrFile as FileItem).files?.some((f) => f.type === 'folder')) {
        return faSquareMinus;
      }
      return faSquareCheck;
    }

    const total = hasPartialPermissions(
      (folderOrFile as Folder).files || [],
      userId,
      false
    );
    const descendantsChecked = hasPartialPermissions(
      (folderOrFile as Folder).files || [],
      userId,
      true
    );

    return descendantsChecked === total ? faSquareCheck : faSquareMinus;
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <GenericModal
      isOpen={isOpen}
      onClose={() => {
        setSelectedUsers(originallyAllowedUsers);
        setRemovedUsers([]);
        onClose();
      }}
    >
      <div className='flex h-full flex-col'>
        <div className='flex items-center'>
          <div className='w-full'>
            <div className='font-bold text-base'>
              View which external parties have access to this folder
            </div>
            <br />
            <div className='text-base px-4'>
              Select users to share this folder with:
            </div>
          </div>
        </div>
        <br />

        {investorList.length > 0 ? (
          <>
            <div className='flex items-start' key='div-main-all-users'>
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <label
                className='flex items-center cursor-pointer'
                htmlFor='enable-tab'
                key='label-tab-all-users'
                onClick={handleShareWithAllUsers}
              >
                <input
                  type='checkbox'
                  key='checkbox-all-users'
                  data-testid='checkbox-all-users'
                  className='form-checkbox h-5 w-5 text-blue-600'
                  checked={selectedUsers.length === investorList?.length}
                  onChange={handleShareWithAllUsers}
                />
                <h3
                  key='user-name-all-users'
                  className='text-base ml-2 break-all max-w-xs'
                >
                  Share with all
                </h3>
              </label>
            </div>
            <br />
            <hr className='w-1/2 self-center' />
            <br />
          </>
        ) : null}

        {investorList.map((user) => (
          <div key={`div-main-${user.id}`}>
            <div
              className='flex items-start'
              key={`div-sub-${user.id}`}
              id={`div-sub-${user.id}`}
            >
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <label
                className='flex items-center cursor-pointer'
                htmlFor='enable-tab'
                key={`label-tab-${user.id}`}
                id={`label-tab-${user.id}`}
                onClick={() => toggleUserSelection(user.id)}
              >
                <FontAwesomeIcon
                  icon={getUserPermissionIcon(user.id)}
                  size='xl'
                  className={`px-2 ${
                    getUserPermissionIcon(user.id) === faSquare
                      ? 'grey-icon'
                      : 'text-blue-300'
                  }`}
                  data-testid={`checkbox-${user.id}`}
                />
                <h3
                  key={`user-name-${user.id}`}
                  id={`user-name-${user.id}`}
                  className='text-base ml-2 break-all max-w-xs'
                >
                  {user.label}
                </h3>
              </label>
            </div>
            <br />
          </div>
        ))}
        <br />
        <div className='flex justify-center'>
          <Button
            type='submit'
            title='Send'
            color='accent'
            size='sm'
            className='btn btn-accent'
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={saveData}
          >
            Send
          </Button>
        </div>
      </div>
    </GenericModal>
  );
}
