import React, { useMemo, useState, useEffect } from 'react';
import { Checkbox, Input } from 'react-daisyui';
import type { User, UserFields } from '@types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { TableMenuActions, TableSort } from '@/types';
import { DashboardCard } from '@/components/DashboardCard';
import './TableUsers.css';
import { getNewSortStateTable } from '@/utils/tableSort';
import { GenericTable } from '@/components/Table/Table';
import { columns, handleRowClick } from './tableUsers.util';

interface TableUsersProps {
  users: User[];
  // eslint-disable-next-line react/no-unused-prop-types
  test?: boolean;
  userActions: TableMenuActions;
}
function TableUsers({ users = [], userActions }: TableUsersProps) {
  const [sort, setSort] = useState<TableSort<User>>({
    field: 'last_name',
    asc: true,
  });
  const [searchText, setSearchText] = useState<string>('');
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [showClients, setShowClients] = useState<boolean>(false);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(10);

  const sortUsers = (): User[] =>
    users
      ?.sort((c1, c2) => {
        const obj1 =
          sort.field === 'clients'
            ? c1[sort.field]?.length || 0
            : c1[sort.field as UserFields];
        const obj2 =
          sort.field === 'clients'
            ? c2[sort.field]?.length || 0
            : c2[sort.field as UserFields];
        if (obj1 && obj2 && obj1 > obj2) return sort.asc ? 1 : -1;
        return sort.asc ? -1 : 1;
      })
      .sort((a, b) => {
        if (a.enabled === b.enabled) return 0;
        return a.enabled ? -1 : 1;
      })
      .filter((user) =>
        `${user.last_name?.toLowerCase()} ${user.first_name?.toLowerCase()}`.match(
          searchText.toLowerCase()
        )
      )
      .filter((user) => {
        if (!showClients) {
          return user.role !== 'client';
        }

        return true;
      });

  const sortBy = (fieldName: keyof User) => {
    const newSort = getNewSortStateTable(fieldName, sort);
    setSort(newSort);
  };

  const records = useMemo(
    () => sortUsers(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sort, selectedPage, searchText, users, showClients]
  );

  useEffect(() => {
    setSelectedPage(1);
  }, [sort, recordsPerPage, searchText]);

  return (
    <DashboardCard className='p-4' cardBodyclassName='p-0'>
      <div className='mt-3 mb-1'>
        <div className='relative text-gray-600 focus-within:text-gray-400'>
          <span className='absolute inset-y-0 left-[260px] flex items-center pl-2'>
            <button
              type='submit'
              className='p-1 focus:outline-none focus:shadow-outline'
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </span>
          <Input
            data-testid='users-search-input'
            className='border-accent w-[300px]'
            placeholder='Search Users'
            size='sm'
            defaultValue={searchText}
            onChange={(event) => setSearchText(event.target.value)}
          />
          <span className='ml-10 text-white'>Show clients</span>
          <Checkbox
            checked={showClients}
            data-testid='showClients'
            className='border-accent relative top-[7px] ml-2'
            onChange={() => setShowClients(!showClients)}
          />
        </div>
      </div>
      <GenericTable
        className=''
        tableName='users'
        data={records}
        columns={columns(userActions)}
        sortBy={sortBy}
        sort={sort}
        recordsPerPage={recordsPerPage}
        selectedPage={selectedPage}
        onPageSelect={setSelectedPage}
        onSelectRowsChange={setRecordsPerPage}
        onRowClick={(row) => handleRowClick(row, userActions)}
      />
    </DashboardCard>
  );
}

export default TableUsers;
export { TableUsers };
