import moment from 'moment';
import type { KVPairs } from '@types';

export const prettifyKey = (key: string) => key.replace(/[_]/g, ' ');

export const prettifyValue = (value: string | number) => {
  if (typeof value === 'number') {
    return value.toLocaleString();
  }
  return value;
};

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const removePrefixOnObjectProperties = (
  obj: KVPairs | KVPairs[],
  prefix: string
) => {
  // Check if obj is an object and not null
  if (typeof obj === 'object' && obj !== null) {
    // Create an empty object or array to store the renamed properties
    const renamedObj: KVPairs | KVPairs[] = Array.isArray(obj) ? [] : {};
    let newKey = '';

    // Loop through all the properties of the object or array using the forEach method
    Object.entries(obj).forEach(([key, value]) => {
      // Check if the property name starts with the given prefix
      if (key.startsWith(prefix)) {
        // Remove the prefix from the property name
        newKey = key.substring(prefix.length);
        // Recursively call the function to rename any nested properties
        renamedObj[newKey as unknown as number] =
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          removePrefixOnObjectProperties(value, prefix);
      } else if (key === 'amount_monthly') {
        newKey = 'MRR';
        // eslint-disable-next-line no-param-reassign
        value = formatter.format(value as number);
        renamedObj[newKey as unknown as number] =
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          removePrefixOnObjectProperties(value, prefix);
      } else if (key === 'closed_date') {
        // eslint-disable-next-line no-param-reassign
        value = moment(new Date(value as string)).format('MMM DD, YYYY');
        renamedObj[key as unknown as number] = removePrefixOnObjectProperties(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          value,
          prefix
        );
      } else {
        // If the property name doesn't start with the prefix, add it to the renamed object as is
        renamedObj[key as unknown as number] = removePrefixOnObjectProperties(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          value,
          prefix
        );
      }
    });

    return renamedObj;
  }

  // If obj is not an object or is null, return it as is
  return obj;
};

export const filterDeals = (obj: KVPairs | KVPairs[], validDeals: string[]) => {
  if (Array.isArray(obj)) {
    return obj;
  }
  if (obj?.deals) {
    return {
      ...obj,
      deals: obj.deals.filter(
        (deal) => deal?.status && validDeals.includes(deal.status)
      ),
    };
  }

  return obj;
};

export const safeParseJson = (
  str: string,
  defaultReturn: unknown | unknown[]
): unknown => {
  try {
    return JSON.parse(str);
  } catch (err) {
    return defaultReturn;
  }
};
