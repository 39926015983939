import { Menu } from 'react-daisyui';
import { QueueListIcon } from '@heroicons/react/24/solid';

import { EmptyState } from '@/components/EmptyState';

import type {
  FileSearchItem,
  FileSelectAction,
  FolderSelectAction,
} from './types';
import { FolderOrFileComponent } from './FileDisplay';
import { MAX_SEARCH_RESULTS } from './constants';

// eslint-disable-next-line import/prefer-default-export
export function FileSearchResults({
  results,
  onClick,
}: {
  results: FileSearchItem[];
  onClick: (item: FileSearchItem) => void;
}) {
  const resultsMessage =
    results.length >= MAX_SEARCH_RESULTS
      ? `${MAX_SEARCH_RESULTS}+ search results`
      : `${results.length} search results`;

  return (
    <>
      <div className='font-bold bg-base-100 flex item-center text-sm sticky top-0 z-20 p-4'>
        <QueueListIcon className='w-5 mr-2' />
        {resultsMessage}
      </div>
      {results.length ? (
        <Menu data-testid='file-search-results'>
          {results?.map((result) => (
            <FolderOrFileComponent
              key={result.id}
              item={result}
              onFileSelect={onClick as FileSelectAction}
              onFolderSelect={onClick as FolderSelectAction}
              currentFolderPath={result.linkPath}
            />
          ))}
        </Menu>
      ) : (
        <EmptyState name='files' />
      )}
    </>
  );
}
