import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getUserFullName } from '@/components/UserList/utils';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { Client, SimpleUserDto, User } from '@types';
import { PopupButton } from '@typeform/embed-react';
import { GenericWizardSteps } from '@/components/GenericWizard/GenericWizard';
import { debounce } from 'lodash';
import { fetcher } from '@/utils/queries';
import Select, { SingleValue } from 'react-select';

interface SelectOptions {
  id: number;
  label: string;
  first_name?: string;
  last_name?: string;
}

interface SelectUserStepProps extends GenericWizardSteps {
  company: Client;
  disableNext: (value: boolean) => void;
  updateUser: (value: User | undefined) => void;
  onClose: () => void;
}

export default function SelectUserStep({
  company,
  disableNext,
  updateUser,
  onClose,
}: SelectUserStepProps) {
  const { auth }: IUserContext = useUserContext();
  const [searchMap, setSearchMap] = useState<SelectOptions[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<
    SingleValue<SelectOptions> | undefined
  >();
  const users = useRef<SimpleUserDto[]>([]);

  useEffect(() => {
    disableNext(!selectedUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  const autocomplateOptions: SelectOptions[] = users.current.map((user) => ({
    id: user.id || 0,
    label: `${user.first_name} ${user.last_name}`,
    first_name: user.first_name,
    last_name: user.last_name,
  }));

  // eslint-disable-next-line  @typescript-eslint/no-unsafe-assignment
  const FORM_ID: string = (import.meta.env || process.env)
    ?.VITE_TYPEFORM_ADD_NEW_USER;

  const getHiddenProps = useMemo<Record<string, string>>((): Record<
    string,
    string
  > => {
    const userFullName = auth?.id ? getUserFullName(auth) : '';
    const addedById = auth?.id.toString() || '';
    const companyId = company?.hubspot_id || '';
    const companyName = company?.client_name || '';

    return {
      company_name: companyName,
      added_by_full_name: userFullName,
      added_by_id: addedById,
      company_id: companyId,
    };
  }, [auth, company]);

  const apiUsersByEmailCall = async (newQuery: string) => {
    try {
      const role = 'investor';
      const transactionsListAPIUrl = '/api/users/list';
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const res = await fetcher(
        `${transactionsListAPIUrl}/${role}/${newQuery.toLowerCase()}`
      );
      return res as SimpleUserDto[];
    } catch {
      return [];
    }
  };

  const filterUsers = async (newQuery: string) => {
    users.current = await apiUsersByEmailCall(newQuery);
    const searchInputs: SelectOptions[] = users.current
      .filter(
        (user) =>
          user.id !== auth?.id && !user.clientsIds?.includes(company?.id || 0)
      )
      .map((user) => ({
        id: user.id || 0,
        label: `${user.first_name} ${user.last_name}`,
        first_name: user.first_name,
        last_name: user.last_name,
      }));
    setSearchMap(searchInputs);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async (newQuery: string) => {
      await filterUsers(newQuery);
    }, 500),
    []
  );

  const handleSearchQueryChange = useCallback(
    async (newQuery: string) => {
      setSearchQuery(newQuery);
      await debouncedSearch(newQuery);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch, autocomplateOptions]
  );

  return (
    <div title='Select User'>
      <h1 className='text-lg font-bold'>Select User</h1>
      <br />
      <div>Search existing investors by email address:</div>
      <div data-testid='select-user-add'>
        <Select
          className='react-select-container border-accent mb-2'
          classNamePrefix='react-select'
          placeholder='Search users ...'
          closeMenuOnSelect
          isClearable
          options={searchMap}
          inputValue={searchQuery}
          value={selectedUser}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onInputChange={handleSearchQueryChange}
          onChange={(value) => {
            setSelectedUser(value);
            if (value) {
              updateUser({
                id: value.id,
                first_name: value.first_name,
                last_name: value.last_name,
              } as User);
            } else {
              updateUser(undefined);
            }
            setSearchMap([]);
          }}
          filterOption={() => true} // Only filter querying the api
          onFocus={() => setSearchMap([])}
        />
      </div>
      <br />
      <br />
      <div className='flex justify-center items-center'>
        <div style={{ borderBottom: '1px solid white', width: '50%' }} />
        <span className='px-2'>OR</span>
        <div style={{ borderBottom: '1px solid white', width: '50%' }} />
      </div>
      <br />
      <div>Add a new Internal or External User:</div>
      <PopupButton
        id={FORM_ID}
        hidden={getHiddenProps}
        transitiveSearchParams={['']}
        className='btn btn-accent btn-sm my-2'
        key='add-user-investor-button'
        onClose={onClose}
      >
        Complete Form
      </PopupButton>
    </div>
  );
}
