import { useMemo } from 'react';
import { Alert, Menu, Card } from 'react-daisyui';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { FolderOrFileComponent } from '@/components/FileBrowser/FileDisplay';

import { get } from '@/utils/queries';
import { slugify } from '@/utils/string';

import { IUserContext, useUserContext } from '@/context/UserContext';
import { Spinner } from '@/components/Spinner';
import { Client } from '@types';
import useFileData from '@/hooks/useFileData';
import { getMostRecentFiles } from './utils';
import { MAX_RECENT_FILES } from './constants';
import { Folder } from '../../../../shared/types/files';

export default function FilesCard({ clientId }: { clientId: number }) {
  const { getFileData } = useFileData();
  const { data: files } = getFileData(clientId);
  const { data: company } = get<Client>(`account/${clientId}`);
  const { auth }: IUserContext = useUserContext(); // Use the context

  const recentFiles = useMemo(() => {
    if (!files) return [];

    return getMostRecentFiles(files as Folder, MAX_RECENT_FILES);
  }, [files]);

  const companySlug: string = useMemo(() => {
    if (!company) return '';
    return slugify(company.client_name || '');
  }, [company]);

  if (!files || !company || !auth) {
    return (
      <Card.Body className='w-full h-32 flex items-center justify-center'>
        <Spinner className='opacity-50' />
      </Card.Body>
    );
  }

  return (
    <div data-testid='recent-files-card'>
      <Link
        to={`/files/${
          company?.hubspot_id ? company?.hubspot_id || '' : companySlug
        }`}
        className='flex items-center'
      >
        <FontAwesomeIcon
          icon={faFolderOpen}
          className='mr-5 text-accent'
          size='2x'
        />
        <h3 className='font-bold text-base'>Recent Files</h3>
      </Link>
      {recentFiles.length > 0 && (
        <Menu className='overflow-y-auto'>
          {recentFiles.map((file) => (
            <FolderOrFileComponent
              key={file.id}
              item={file}
              showLocation={false}
              currentFolderPath={file.linkPath}
            />
          ))}
        </Menu>
      )}
      {recentFiles.length === 0 && (
        <Alert
          className='rounded-2xl opacity-60 text-sm text-left w-full mt-4'
          icon={<ExclamationCircleIcon className='w-8 h-8' />}
          status='warning'
        >
          <div>There are no files changed recently.</div>
        </Alert>
      )}
    </div>
  );
}
