import type { Folder, File, FileItem } from '@types';
import FolderComponent from '@/components/FileBrowser/FolderComponent';
import FileComponent from '@/components/FileBrowser/FileComponent';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { FileSelectAction, FolderSelectAction, FileSearchItem } from './types';

export function FolderOrFileComponent({
  item,
  onFileSelect,
  onFolderSelect,
  showLocation = true,
  openUpload,
  currentFolderPath,
  currentFolderIdPath,
  disabledClick,
}: {
  item: FileItem | FileSearchItem;
  onFileSelect?: FileSelectAction;
  onFolderSelect?: FolderSelectAction;
  showLocation?: boolean;
  openUpload?: (folder: Folder) => void;
  currentFolderPath?: string;
  currentFolderIdPath?: string;
  disabledClick?: boolean;
}) {
  const { auth }: IUserContext = useUserContext(); // Use the context

  const checkPermissionsForInvestors = () => {
    if (item.id === item.teamId) {
      // Its the main folder
      return true;
    }
    if (auth?.isInvestor && item.permissions && item.permissions?.length > 0) {
      return item.permissions?.[0].allowedUsers.includes(auth.id);
    }
    return false;
  };

  // eslint-disable-next-line no-nested-ternary
  return item.type === 'folder' ? (
    !auth?.isInvestor || checkPermissionsForInvestors() ? (
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      <FolderComponent
        folder={item as Folder}
        onFileSelect={onFileSelect}
        onFolderSelect={onFolderSelect}
        openUpload={openUpload}
        currentFolderPath={currentFolderPath as string}
        currentFolderIdPath={currentFolderIdPath as string}
      />
    ) : null
  ) : !auth?.isInvestor || checkPermissionsForInvestors() ? (
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    <FileComponent
      file={item as File}
      showLocation={showLocation}
      disabledClick={disabledClick}
      currentFolderPath={currentFolderPath as string}
      currentFolderIdPath={currentFolderIdPath as string}
    />
  ) : null;
}
